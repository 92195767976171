.profile-card--images{
  max-width: 614px;
  margin: 0 auto;
}
.profile-card--images__cover {
  height: 127px;
  background-position: center;
}

.musician-profile {
  .bio-song{
    p {
      margin-bottom: 5px;
    }
  }
  .send-message-button{
    text-align: center;
    margin-top: 24px;
    width: auto;
  }

  .container{
    margin-top: -54px;
  }

  .no-user{
    text-align: center;
  }
  
    .account-options {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;

      a {
        background: black;
        color: white;
        margin-right: 3px;
        padding: 9px 18px;
        font-size: 12px;
        width: 100%;
        text-align: center;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    .details{
      & > * {
        margin-top: 24px;
        color: #3B3B3B;
      }

      a{
        text-decoration: underline;
      }
    }

    .form-group{
      margin-top: 24px;

      :last-child{
        margin-bottom: 24px;
      }
    }

    .upload-options{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      label, button{
         background: var(--pink);
         width: 100%;
         text-align: center;
         color: white;
         padding: 16px 0;
         border-radius: 8px;
         margin-top: 16px;
         cursor: pointer;
       }
    }
}

.profile{
    .link-button{
      margin-top: 24px;
    }
    &__info{
      margin: 32px 0;
      display: flex;
      justify-content: space-between;

      button{
        background: white;
        color: var(--pink);
      }

      span{
        font-size: 12px;
      }

      p{
        color: #9D9D9D;
        margin-top: 4px;
      }
    }
}

.media-card{
  margin-top: 40px;
    iframe {
      width: 100%;
      border: none;
    }

    div{
      display: flex;
      justify-content: space-between;

      p {
        width: 80%;
      }
      button{
        margin: 0;
      }
    }

    &:last-child{
      margin-bottom: 40px;
    }

    img{
      max-width: 100%;
    }
}