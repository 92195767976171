// text utility classes
.text-12{
    font-size: 12px;
}

.text-14{
    font-size: 14px;
}

.text-16{
    font-size: 16px;
}

.text-18{
    font-size: 18px;
}

.text-24{
    font-size: 24px;
}

.bold{
    font-style: bold;
}

.italic{
    font-style: italic;
}