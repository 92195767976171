.radio-button{
    width: 16px;
    height: 16px;
    border: 1px solid #8C8C8C !important;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .indicator{
        background: var(--pink);
        width: 80%;
        height: 80%;
        border-radius: 100%;
        margin: auto;
    }
}