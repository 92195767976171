.slot-card{
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(103, 103, 103, 0.248607);
    border-radius: 12px;
    min-width: 160px;
    padding: 16px 8px;
    margin-right: 20px;

    .primary-button{
        padding: 6px 20px;
        text-align: center;
        margin-top: 8px;

        &:disabled{
            cursor: auto;
            background: grey;
        }
    }

    &__date{
        font-weight: 500;
    }

    &__time{
        margin: 8px  0;
    }
}