.sign-up{
    margin-top: 40px;

    .title {
        p {
            margin-top: 8px;
        }
    }
    .links{
        margin-top: 40px;
        width: 80%;

        .musician-link{
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #F3F3F3;
        }
        div{
            display: flex;

            a {
                margin-left: 16px;
                b{
                    font-size: 18px;
                }
                i{
                    font-size: 12px;
                }
            }
        }
        img {
            width: 48px;
            height: 48px;
            object-fit: cover;
        }
    }
}