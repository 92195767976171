
.ad-card{
    box-shadow: 0px 2px 4px rgba(103, 103, 103, 0.248607);
    border-radius: 8px;
    margin-bottom: 24px;
    // max-width: 500px;
    cursor: pointer;

    .st-message{
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 375px) {
            flex-direction: column;
        }
    }

    .new-applications-banner{
        text-align: center;
        padding: 5px 0;
        color: white;
        background: var(--pink);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        font-size: 14px;
    }

    .status-box{
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
    }

    .options{
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        &__button{
            position: relative;
            // right: 50px;
            background: white;
            width: min-content;
        }
        
        .dropdown{
            border: 1px solid #DDDD;
            box-shadow: 0px 2px 4px rgba(103, 103, 103, 0.248607);

            ul{
                display: flex;
                flex-direction: column;
                padding: 12px 24px;

                & > *:not(:first-child) {
                    border-top: 1px solid var(--light-grey);
                }
                
                & > * {
                    padding: 5px 0;
                }
            }
            button{
                color: var(--pink);
                background: white;
            }
        }
    }

    &__cover{
        height: 140px;
        object-fit: cover;
        width: 100%;
        border-radius: 8px 8px 0 0;
    }

    &__date{
        position: absolute;
        color: white;
        background: black;
        width: min-content;
        min-width: 160px;
        text-align: center;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding: 5px;
    }

    &__description{
        padding: 3px 12px 12px;
        p{
            font-size: 12px;
            color: var(--light-grey);
        }
    }

    &__tags{
        padding:0;
        margin-top: 12px;
        .genre-tag{
            margin-right: 10px;
            list-style-type: none;
        }
    }
        
    .controls{
        display: flex;
        margin-top: 20px;
    }
    .application-withdraw{
        text-align: center;
    }
}
