.update-profile{
    .profile-card--images{
        &__cover{
            height: 127px;
        }

        &__cover,&__profile{
            cursor: pointer;
        }
    }

    .profile-images__controls{
        label {
            text-decoration: underline;
            color: var(--pink);
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .form-group, .tags,.primary-button{
        margin-top: 32px;
    }

    .primary-button{
        margin-bottom: 32px;
    }
}