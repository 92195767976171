
.edit-gig{
    
    &__update-tags .options{
        display: flex;
        margin-top: 24px;
    }
    .edit-dates-button{
        color: var(--pink);
        border: 1px solid var(--pink);
        padding: 10px;
        border-radius: 8px;
        width: 70px;
        font-size: 14px;
        text-align: center;
        margin-top: 20px;
    }

    #dates-input{
        background-color: white;
    }

    .change-gig-photo{
        display: inline-block;
        margin-bottom: 40px;
        color: var(--pink);
        text-decoration: underline;
        cursor: pointer;
    }

    .controls{
        display: flex;
        flex-direction: column;
        margin: 40px 0;

        * {
            margin-top: 10px;
        }
    }

    .form-group{
        margin-bottom: 32px;

        input {
            font-size: 16px;
        }

        select{
            border: 1px solid #DDDD;
            padding: 10px 2px;
            outline: none;
        }
    }
}

.tags{
    * {
        margin-right: 10px;
    }
}