.musician-card{
    box-shadow: 0px 2px 4px rgba(103, 103, 103, 0.252786);
    border-radius: 8px;
    display: flex;
    padding: 12px;
    margin-bottom: 32px;
    cursor: pointer;
    
    &__details{
        min-height: 72px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 20px;
        width: 85%;

        .exp-sta{
            display: flex;
            justify-content: space-between;
        }
    }

    &__profile{
        display: block;
        height: 64px;
        width: 64px;
        border-radius: 100%;
        object-fit: cover;
    }

    &__exp{
        right: 24px;
        color: var(--light-grey);
    }

    &__name{
        font-weight: 500;
    }

    &__tags{
        margin-top: 10px;
        *{
            margin-right: 10px;;
        }
    }
}
