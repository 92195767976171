.gig-application, .send-message{
    margin-top: 32px;
}

.message-box{
    input{
        font-size: 16px;
        border: none;
        outline: none;
        width: 100%;
    }

    h2, input{
        border-bottom: 1px solid #DDDDDD;
        padding: 16px 0;
    }

    .message{
        margin-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #DDDDDD;
        margin-bottom: 40px;
        line-break: loose;
        outline: none;

        p{
            margin: 5px 0;
        }
    }
}