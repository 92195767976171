.search{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(103, 103, 103, 0.254562);
    border-radius: 16px;
    border: 1px solid #DDDDDD;
    padding: 0 16px;
    margin-bottom: 24px;
    input{
        border: none;
        outline: none;
        height: 40px;
        width: 90%;
        font-size: 14px;
        font-weight: bold;
        margin-left: 20px;
    }

    a{
        display: flex;
        align-items: center;
    }
}