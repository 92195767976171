.review-applications{
    .applicant-controls{
        display: flex;
        margin-top: 20px;
    }

    .gig-cover{
        height: 220px !important;
    }
    
    h1{
        text-align: center;
        margin: 50px 0;
    }

    &__slot{
        margin: 30px 0;

        .slot_details{
            padding-top: 30px;
        }
    }
    .applicant-detail{
        display: flex;

        img{
            display: block;
            width: 57px;
            height: 57px;
            margin-right: 10px;
            border-radius: 100%;
            object-fit: cover;
        }
    }
    &__applicants{
        display: flex;
        flex-direction: column;

        &--application{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;

            button{
                max-width: 108px;
            }
        }
    }
}