.loader-container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--pink);

    p{
        color: white;
        margin-bottom: 32px;
    }
}