.search-config{
    margin-top: 24px;
    &__main{
        border: 1px solid #DDDD;
        border-radius: 16px;
        margin-bottom: 24px;
    }

    .tabbed-nav{
        a:first-child{
            border-right: 1px solid #DDDD;
        }
    }

    button{
        margin-bottom: 20px;
    }

    fieldset{
        border: none;
        display: flex;
        flex-direction: column;

        :last-child{
            border: none;
        }

        label {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #DDDDDD;
            padding: 12px 18px;
        }
    }
}