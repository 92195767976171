@import '../vars.scss';

#sign-in{
    min-height: 100%;
    .form-group{
        margin-top: 40px;
    }

    a {
        text-align: center;
    }

    button{
        margin: 8px 0;
    }
}

#sign-in .button, .home .button{
    margin: 40px auto;
    padding: 12px;
    width: 100%;
    background-color: var(--pink);
    border: none;
    color: white;
}

.form-group{
    display: flex;
    flex-direction: column;

    label{
        font-size: 12px;
    }

    input{
        border: none;
        border-bottom: 1px solid #B1B1B1;
        font-size: 16px;
        padding: 7px 0;
        margin-top: 4px;
    }

    .valid,.invalid{
        font-size: 12px;
        margin-top: 5px;
    }

    .valid{
        color: var(--valid-green);
    }

    .invalid{
        color: red;
    }
}

.password-reset-modal{
    width: 80vw;
    max-width: 295px;
}