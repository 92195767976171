.my-gigs, .gigs-list {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    &__new-gig-link{
        background-color: var(--pink);
        color: white;
        padding: 8px 18px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::after{
            content: url('../../assets/white-add-icon.svg');
            display: flex;
        }
    }
}
.nothing-to-show{
    color: var(--grey);
    text-align: center;
}

.loader{
    margin: 12px auto;
    width: min-content;
}