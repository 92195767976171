// Buttons
button {
  border: none;
  font-size: 16px;
  cursor: pointer;

  :disabled {
    background: grey;
  }
}

.rounded {
  border-radius: 23px;
}

.outline-button {
  text-align: center;
  width: 100%;
  color: var(--pink);
  padding: 12px;
  border: 1px solid var(--pink);
  background: white;
  font-size: 14px;
}

.link-button {
  background: white;
  color: var(--pink);
  margin: 0 auto;
  display: block;
}

.primary-button {
  background: var(--pink);
  width: 100%;
  color: white;
  padding: 12px;
  border: none;
  color: white;
}

//Links
a {
  display: block;
  text-decoration: none;
  color: black;
}

//////////////////////

.modal-bg {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: #00000080;
  top: 0;
  left: 0;
  z-index: var(--z-middle);
}

.modal-content {
  position: relative;
  background: white;
  max-width: 500px;
  border-radius: 16px;
  padding: 40px 24px;
  margin: 0 auto;
  top: 20vh;
  z-index: var(--z-top);

  .close-button {
    position: absolute;
    right: 20px;
    cursor: pointer;
    background: white;

    img{
      height: 24px;
    }
  }
}

.modal-container{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  padding: 0 16px;
  max-width: 614px;
  margin: 0 auto;
}

.top-nav {
  position: relative;
  z-index: 1000;
  div{
    display: flex;
    justify-content: space-between;
    padding: 16px 17px;
    max-width: 614px;
    margin: 0 auto;
  }

  .logo {
    font-weight: bold;
    font-size: 18px;
    color: var(--pink);
  }

  .profile-photo{
    width: 28px;
    height: 28px;
    background-size: cover;
    border-radius: 100%;
  }
}

.tabbed-nav {
  display: flex;
  box-shadow: 0 2px 4px 0 rgba(103, 103, 103, 0.15);

  div{
    max-width: 614px;
    width: 100%;
    display: flex;
    margin: 0 auto
  }

  a {
    width: 50%;
    text-align: center;
    padding: 10px 0;
    font-weight: 500;
    color: var(--grey);
  }

  .active {
    border-bottom: 2px solid var(--pink);
  }
}


.withShadow{
  box-shadow: 0 2px 4px 0 rgba(103,103,103,0.15);
}

//Tag stuff

.genre-tag{
  background: #C9F9F6;
  border-radius: 15.5px;
  padding: 6px 12px;
  font-size: 14px;
  list-style: none;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 5px;
}

.removable{
  &::after{
    display: inline-flex;
    content: url('assets/close-icon.svg');
    margin-left: 5px;
  }
}

.update-tag {
  border: 1px solid var(--pink);
  border-radius:15.5px;
  background: white;
  padding: 6px 12px;
  display: inline;
  font-size: 14px;
  &::after{
    content: "+";
    margin-left: 5px;
    color: var(--pink);
    font-size: 18px;
  }
}

.dropdown{
  position: absolute;
  margin-top: 24px;
  min-width: auto;
  background: white;
}

//for gig creation and profile creation
.back{
  &::before{
    display: inline-block;
    content: url('./assets/chevron.svg');
    transform: rotate(-180deg);
    margin-right: 10px;
  }
}

.next{
  &::after{
    display: inline-block;
    content: url('./assets/chevron.svg');
    margin-left: 10px;
  }
}

.next,.back{
  font-size: 16px;
  display: flex;
  align-items: center;
  &::after,&::before{
    height: 16px;
  }

  :disabled{
    color: var(--grey);
    path{
      fill: var(--grey);
    }
  }
}

input:focus{
  outline: none;
}

p{
  overflow-wrap: break-word;
}