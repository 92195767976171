.musician-signup{
    nav{
        display: flex;
        justify-content: space-between;
        margin-top: 51px ;
    }
}

.new-account{
    margin-top: 40px;
    .form-group{
        margin-top: 32px;
    }

    p{
        margin: 12px 0;
    }

    .primary-button{
        margin-top: 32px;
    }
}