.react-calendar{
    margin: auto;
    border-radius: 16px;
    box-shadow: 0px 2px 4px rgba(103, 103, 103, 0.252786);
    border: none;
    font-family: Roboto;

    &--selectRange &__tile--hover{
        background: #ff7fb0;
    }

    *{
        font-size: 14px;
    }

    &__tile{
        height: 46px;
        width: 46px;

        &:enabled:hover{
            background: var(--pink);
        }
        &--active{
            background: var(--pink);

            &:enabled:hover{
                background: #bd1958;
            }
        }
    }
    
        
    &__month-view__weekdays,&__navigation{
        padding: 16px;
        background: var(--pink) !important;
        
        *{
            color: white;
        }
        :hover{
            background: var(--pink) !important;
        }
    }

    &__navigation{
        margin-bottom: 0;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        &__arrow:nth-child(5){
            border-top-right-radius: 16px;
        }
        button{
            background: var(--pink) !important;
            &:first-child{
                border-top-left-radius: 16px;
            }
        }
    }
}

.date-picker-component{
  li{
    list-style: none;
  }

  .dates{
    display: flex;
    cursor: pointer;
  }

  .label{
      color: var(--light-grey);
      margin-bottom: 10px;
  }

  .selected-dates{
      margin-top: 24px;
  }

  .st-text{
      margin-top: 24px;
      color: var(--grey);
      font-style: italic;
  }
}