.genre-tags-hook{
    .search-section{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 12px;
        button{
            margin: 0;
        }
    }

    .genre-tag{
        cursor: pointer;
    }
    .tag_search{
        width: 100%;
        height: 40px;
        max-width: 268px;
        background: #F3F3F3;
        border-radius: 19.5px;
        padding-inline-start: 10px;
        border: none;
        outline: none;
    }

    .suggested-results{
        li{
            list-style-type: none;
            border-bottom: 1px solid #DDDDDD;
            padding: 12px 17px;
            color: #3B3B3B;
        }

        :last-child{
            border: none;
        }
    }

    .suggested-tags__container{
        .genre-tag{
            margin-top: 10px;
        }
    }

    &__selected-tags{
        padding: 16px 10px;

        .genre-tag{
            margin-right: 10px;
        }
    }
}