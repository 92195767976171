// .perf-space {

// }

.image_input {
  width: 100%;
  background: url("../../assets/image-add-bg.jpg");
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.new-gig{
  .title{
    margin: 40px 0;
  }

  button{
    margin: 0;
  }

  .navigation-buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 51px;
  }

  .single-button{
    justify-content: flex-end;
  }

  .form-group{
    margin: 32px 0;

    .email-change{
      display: flex;
      justify-content: space-between;
    }
  }

  .buttoned-list{
    .selected{
      background: var(--pink);
      
      *{
        color: white !important;
      }
    }

    li{
      background: white ;
      list-style: none;
      margin: 16px 0;
      padding: 9px 0;
      text-align: center;
      border-radius: 36px;
      border: 2px solid var(--pink);

      button {
        color: var(--pink);
        background: transparent;
        font-size: 14px;
      }
      p:first-child{
        color: black;
      }
      p:last-child{
        color: grey;
      }
    }
  }

  .setlist{
    margin-top: 24px;
  }

  .songs{
    list-style-type:armenian;
    display: flex;
    cursor: pointer;
  }
}