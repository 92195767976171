.unreviewed-alert-banner{
    background: var(--pink);
    color: white;
    padding: 12px 0;
    display: flex;
    justify-content: center;

    a{
        color: white;
        text-decoration: underline;
    }
}

#setlist{
    max-width: 250px;
}

.view-gig{
    &__banner{
        max-width:614px;
        height: 211px;
        margin: 0 auto;
        
        img{
            display: block;
            object-fit: cover;
            width: 100%;
            height: 211px;
        }
    }

    &__edit-link{
        background: black;
        color: white;
        text-align: center;
        padding: 9px 150px;
        border-radius: 8px;
        margin-bottom: 24px;
    }

    &__details{
        margin-bottom: 40px;
        h2{
            margin: 16px 0;
        }
        p {
            color: var(--light-grey);
        }
        #gig-description{
            margin: 26px 0;
        }

        .genre-tag{
            margin-right: 10px;
        }
    }

    &__times{
        h4{
            margin-bottom: 16px;
        }
    }

    &__slots{
        display: flex;
        overflow-x: scroll;
        padding: 25px 0;
    }

    &__info{
        margin-top: 40px;

        .detail{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #F3F3F3;
            padding: 18px 0;

            :first-child {
                color: #8C8C8C;
            }
        }
    }

    .stage-image{
        margin: 30px 0;
    }
}